<template>
  <FontAwesomeIcon
    v-if="
      iconState.get(importPath.set + importPath.icon) &&
      iconState.get(importPath.set + importPath.icon) !== 'loading'
    "
    :icon="iconState.get(importPath.set + importPath.icon)"
    :class="classes + ' fa-fw'"
    :data-testid="testid"
  />
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useStorage } from '@vueuse/core';
import { UseStateKeys } from '@/useStateKeys';
import type { IconData } from '../server/transformers/iconTransformer';

const props = defineProps({
  icon: {
    type: Object as PropType<IconData>,
    required: false,
    default: null,
  },
  iconClass: {
    type: String,
    required: false,
    default: '',
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

const importPath = parseIcon();

const iconState = useState(UseStateKeys.FA_ICONS, () => new Map<string, any>());

await getIcon();

async function getIcon() {
  let iconData = {};
  const icon = iconState.value.get(importPath.set + importPath.icon);

  if (icon || !importPath?.set) {
    return;
  } else {
    iconState.value.set(importPath.set + importPath.icon, 'loading');

    if (
      import.meta.client &&
      window.localStorage &&
      window.localStorage[importPath.set + '-' + importPath.icon]
    ) {
      iconData = await JSON.parse(
        window.localStorage[importPath.set + '-' + importPath.icon],
      ).icon;
    } else {
      try {
        iconData = await $fetch<object>(
          `/api/fa/${importPath.set}_${importPath.icon}`,
        );
      } catch (error) {
        iconData = null;
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    if (iconData) {
      iconState.value.set(importPath.set + importPath.icon, iconData);

      if (import.meta.client) {
        useStorage(importPath.set + '-' + importPath.icon, { icon: iconData });
      }
    }
  }
}

type ImportPathSet =
  | 'brands'
  | 'solid'
  | 'light'
  | 'regular'
  | 'duotone'
  | 'thin';

interface ImportPath {
  set: ImportPathSet;
  icon: string;
}

function parseIcon(): ImportPath {
  if (props.icon) {
    if (props.icon.label) {
      return {
        set: iconsetPath(props.icon.iconSet),
        icon: iconPath(props.icon.label),
      };
    } else {
      return {
        set: 'light' as ImportPathSet,
        icon: 'faFaceSmile',
      };
    }
  } else if (props.iconClass) {
    const icon = props.iconClass.split(' ');
    return {
      set: iconsetPath(icon[0]) as ImportPathSet,
      icon: iconPath(icon[1]),
    };
  } else {
    return {
      set: null as ImportPathSet,
      icon: null,
    };
  }
}

function iconPath(iconCss: string) {
  const iconWords = iconCss.split('-');
  if (iconWords[0] === 'fa') {
    iconWords.shift();
  }
  for (let i = 0; i < iconWords?.length; i++) {
    iconWords[i] = iconWords[i].charAt(0).toUpperCase() + iconWords[i].slice(1);
  }
  const iconName = iconWords.toString().split(',').join('');
  return `fa${iconName}`;
}

function iconsetPath(iconSet: string): ImportPathSet {
  return getIconSetForOldIconData(iconSet);
}

function getIconSetForOldIconData(iconSet: string): ImportPathSet {
  switch (iconSet) {
    case 'fa-brands':
    case 'fab-icons':
    case 'fab':
      return 'brands';
    case 'fa-light':
    case 'fal-icons':
    case 'fal':
      return 'light';
    case 'duotone':
    case 'fad-icons':
    case 'fad':
      return 'duotone';
    case 'fa-solid':
    case 'fas-icons':
    case 'fas':
      return 'solid';
    case 'fa-regular':
    case 'far-icons':
    case 'far':
      return 'regular';
    case undefined:
    case null:
    case '':
      return 'light';
    default:
      return iconSet as ImportPathSet;
  }
}
</script>
